// // ./gatsby-browser.js
import React from 'react'
import { QueryClient, QueryClientProvider, QueryCache } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import './src/assets/styles/global.css'
import { DataProvider } from 'Context/DataProvider'

export const wrapPageElement = ({ element, props }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        refetchOnWindowFocus: false,
        queryCache: new QueryCache({
          onError: (error, query) => {
            // 🎉 only show error toasts if we already have data in the cache
            // which indicates a failed background update
            if (query.state.data !== undefined) {
              console.log(`Something went wrong: ${error.message}`)
            }
          },
        }),
      },
    },
  })

  return (
    <DataProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {element}
      </QueryClientProvider>
    </DataProvider>
  )
}
