exports.components = {
  "component---src-components-business-center-business-center-details-index-js": () => import("./../../../src/components/BusinessCenter/BusinessCenterDetails/index.js" /* webpackChunkName: "component---src-components-business-center-business-center-details-index-js" */),
  "component---src-components-business-center-category-js": () => import("./../../../src/components/BusinessCenter/category.js" /* webpackChunkName: "component---src-components-business-center-category-js" */),
  "component---src-components-business-center-index-js": () => import("./../../../src/components/BusinessCenter/index.js" /* webpackChunkName: "component---src-components-business-center-index-js" */),
  "component---src-components-carrier-component-jobopening-js": () => import("./../../../src/components/CarrierComponent/jobopening.js" /* webpackChunkName: "component---src-components-carrier-component-jobopening-js" */),
  "component---src-components-carrier-component-remotecustomer-js": () => import("./../../../src/components/CarrierComponent/remotecustomer.js" /* webpackChunkName: "component---src-components-carrier-component-remotecustomer-js" */),
  "component---src-components-industries-index-js": () => import("./../../../src/components/Industries/index.js" /* webpackChunkName: "component---src-components-industries-index-js" */),
  "component---src-components-language-dynamic-page-index-js": () => import("./../../../src/components/LanguageDynamicPage/index.js" /* webpackChunkName: "component---src-components-language-dynamic-page-index-js" */),
  "component---src-components-languages-index-js": () => import("./../../../src/components/Languages/index.js" /* webpackChunkName: "component---src-components-languages-index-js" */),
  "component---src-components-recent-index-js": () => import("./../../../src/components/Recent/index.js" /* webpackChunkName: "component---src-components-recent-index-js" */),
  "component---src-components-recent-recent-pages-detail-index-js": () => import("./../../../src/components/Recent/recentPagesDetail/index.js" /* webpackChunkName: "component---src-components-recent-recent-pages-detail-index-js" */),
  "component---src-components-tomedes-insider-tomedes-insider-detail-index-js": () => import("./../../../src/components/tomedesInsider/tomedesInsiderDetail/index.js" /* webpackChunkName: "component---src-components-tomedes-insider-tomedes-insider-detail-index-js" */),
  "component---src-components-translation-conferences-detail-js": () => import("./../../../src/components/translationConferences/detail.js" /* webpackChunkName: "component---src-components-translation-conferences-detail-js" */),
  "component---src-components-translation-conferences-index-js": () => import("./../../../src/components/translationConferences/index.js" /* webpackChunkName: "component---src-components-translation-conferences-index-js" */),
  "component---src-components-translation-industry-translation-industry-detail-index-js": () => import("./../../../src/components/translationIndustry/translationIndustryDetail/index.js" /* webpackChunkName: "component---src-components-translation-industry-translation-industry-detail-index-js" */),
  "component---src-components-translator-hub-category-js": () => import("./../../../src/components/TranslatorHub/category.js" /* webpackChunkName: "component---src-components-translator-hub-category-js" */),
  "component---src-components-translator-hub-index-js": () => import("./../../../src/components/TranslatorHub/index.js" /* webpackChunkName: "component---src-components-translator-hub-index-js" */),
  "component---src-components-translator-hub-translator-hub-details-index-js": () => import("./../../../src/components/TranslatorHub/TranslatorHubDetails/index.js" /* webpackChunkName: "component---src-components-translator-hub-translator-hub-details-index-js" */),
  "component---src-components-translators-translators-js": () => import("./../../../src/components/Translators/Translators.js" /* webpackChunkName: "component---src-components-translators-translators-js" */),
  "component---src-components-translators-translators-list-js": () => import("./../../../src/components/Translators/TranslatorsList.js" /* webpackChunkName: "component---src-components-translators-translators-list-js" */),
  "component---src-components-videos-index-js": () => import("./../../../src/components/Videos/index.js" /* webpackChunkName: "component---src-components-videos-index-js" */),
  "component---src-components-widgetised-pages-index-js": () => import("./../../../src/components/WidgetisedPages/index.js" /* webpackChunkName: "component---src-components-widgetised-pages-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-1-year-guarantee-js": () => import("./../../../src/pages/about/1-year-guarantee.js" /* webpackChunkName: "component---src-pages-about-1-year-guarantee-js" */),
  "component---src-pages-about-available-24-7-js": () => import("./../../../src/pages/about/available-24/7.js" /* webpackChunkName: "component---src-pages-about-available-24-7-js" */),
  "component---src-pages-about-customer-centricity-js": () => import("./../../../src/pages/about/customer-centricity.js" /* webpackChunkName: "component---src-pages-about-customer-centricity-js" */),
  "component---src-pages-about-fast-delivery-js": () => import("./../../../src/pages/about/fast-delivery.js" /* webpackChunkName: "component---src-pages-about-fast-delivery-js" */),
  "component---src-pages-about-leadership-js": () => import("./../../../src/pages/about/leadership.js" /* webpackChunkName: "component---src-pages-about-leadership-js" */),
  "component---src-pages-audio-translation-js": () => import("./../../../src/pages/audio-translation.js" /* webpackChunkName: "component---src-pages-audio-translation-js" */),
  "component---src-pages-birth-certificate-translation-php-js": () => import("./../../../src/pages/birth-certificate-translation.php.js" /* webpackChunkName: "component---src-pages-birth-certificate-translation-php-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contactus-php-js": () => import("./../../../src/pages/contactus.php.js" /* webpackChunkName: "component---src-pages-contactus-php-js" */),
  "component---src-pages-content-writing-services-js": () => import("./../../../src/pages/content-writing-services.js" /* webpackChunkName: "component---src-pages-content-writing-services-js" */),
  "component---src-pages-crisis-communication-center-js": () => import("./../../../src/pages/crisis-communication-center.js" /* webpackChunkName: "component---src-pages-crisis-communication-center-js" */),
  "component---src-pages-cv-and-resume-translation-services-php-js": () => import("./../../../src/pages/cv-and-resume-translation-services.php.js" /* webpackChunkName: "component---src-pages-cv-and-resume-translation-services-php-js" */),
  "component---src-pages-data-destruction-policy-js": () => import("./../../../src/pages/data-destruction-policy.js" /* webpackChunkName: "component---src-pages-data-destruction-policy-js" */),
  "component---src-pages-datadestruction-js": () => import("./../../../src/pages/datadestruction.js" /* webpackChunkName: "component---src-pages-datadestruction-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interpretation-services-js": () => import("./../../../src/pages/interpretation-services.js" /* webpackChunkName: "component---src-pages-interpretation-services-js" */),
  "component---src-pages-iso-certifications-js": () => import("./../../../src/pages/iso-certifications.js" /* webpackChunkName: "component---src-pages-iso-certifications-js" */),
  "component---src-pages-language-translation-service-php-js": () => import("./../../../src/pages/language-translation-service.php.js" /* webpackChunkName: "component---src-pages-language-translation-service-php-js" */),
  "component---src-pages-languagefacts-js": () => import("./../../../src/pages/languagefacts.js" /* webpackChunkName: "component---src-pages-languagefacts-js" */),
  "component---src-pages-languagefacts-sharefacts-js": () => import("./../../../src/pages/languagefacts/sharefacts.js" /* webpackChunkName: "component---src-pages-languagefacts-sharefacts-js" */),
  "component---src-pages-languages-js": () => import("./../../../src/pages/languages.js" /* webpackChunkName: "component---src-pages-languages-js" */),
  "component---src-pages-legal-accessibility-js": () => import("./../../../src/pages/legal/accessibility.js" /* webpackChunkName: "component---src-pages-legal-accessibility-js" */),
  "component---src-pages-legal-cookie-policy-js": () => import("./../../../src/pages/legal/cookie-policy.js" /* webpackChunkName: "component---src-pages-legal-cookie-policy-js" */),
  "component---src-pages-legal-disclaimer-js": () => import("./../../../src/pages/legal/disclaimer.js" /* webpackChunkName: "component---src-pages-legal-disclaimer-js" */),
  "component---src-pages-legal-index-js": () => import("./../../../src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-information-security-js": () => import("./../../../src/pages/legal/information-security.js" /* webpackChunkName: "component---src-pages-legal-information-security-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-of-service-js": () => import("./../../../src/pages/legal/terms-of-service.js" /* webpackChunkName: "component---src-pages-legal-terms-of-service-js" */),
  "component---src-pages-locations-atlanta-translation-services-js": () => import("./../../../src/pages/locations/atlanta-translation-services.js" /* webpackChunkName: "component---src-pages-locations-atlanta-translation-services-js" */),
  "component---src-pages-locations-boston-translation-services-js": () => import("./../../../src/pages/locations/boston-translation-services.js" /* webpackChunkName: "component---src-pages-locations-boston-translation-services-js" */),
  "component---src-pages-locations-chicago-translation-services-js": () => import("./../../../src/pages/locations/chicago-translation-services.js" /* webpackChunkName: "component---src-pages-locations-chicago-translation-services-js" */),
  "component---src-pages-locations-dallas-translation-services-js": () => import("./../../../src/pages/locations/dallas-translation-services.js" /* webpackChunkName: "component---src-pages-locations-dallas-translation-services-js" */),
  "component---src-pages-locations-denver-translation-services-js": () => import("./../../../src/pages/locations/denver-translation-services.js" /* webpackChunkName: "component---src-pages-locations-denver-translation-services-js" */),
  "component---src-pages-locations-houston-translation-services-js": () => import("./../../../src/pages/locations/houston-translation-services.js" /* webpackChunkName: "component---src-pages-locations-houston-translation-services-js" */),
  "component---src-pages-locations-js": () => import("./../../../src/pages/locations.js" /* webpackChunkName: "component---src-pages-locations-js" */),
  "component---src-pages-locations-london-translation-services-js": () => import("./../../../src/pages/locations/london-translation-services.js" /* webpackChunkName: "component---src-pages-locations-london-translation-services-js" */),
  "component---src-pages-locations-los-angeles-translation-services-js": () => import("./../../../src/pages/locations/los-angeles-translation-services.js" /* webpackChunkName: "component---src-pages-locations-los-angeles-translation-services-js" */),
  "component---src-pages-locations-manchester-translation-services-js": () => import("./../../../src/pages/locations/manchester-translation-services.js" /* webpackChunkName: "component---src-pages-locations-manchester-translation-services-js" */),
  "component---src-pages-locations-melbourne-translation-services-js": () => import("./../../../src/pages/locations/melbourne-translation-services.js" /* webpackChunkName: "component---src-pages-locations-melbourne-translation-services-js" */),
  "component---src-pages-locations-miami-translation-services-js": () => import("./../../../src/pages/locations/miami-translation-services.js" /* webpackChunkName: "component---src-pages-locations-miami-translation-services-js" */),
  "component---src-pages-locations-nyc-translation-services-js": () => import("./../../../src/pages/locations/nyc-translation-services.js" /* webpackChunkName: "component---src-pages-locations-nyc-translation-services-js" */),
  "component---src-pages-locations-ottawa-translation-services-js": () => import("./../../../src/pages/locations/ottawa-translation-services.js" /* webpackChunkName: "component---src-pages-locations-ottawa-translation-services-js" */),
  "component---src-pages-locations-philadelphia-translation-services-js": () => import("./../../../src/pages/locations/philadelphia-translation-services.js" /* webpackChunkName: "component---src-pages-locations-philadelphia-translation-services-js" */),
  "component---src-pages-locations-san-diego-translation-services-js": () => import("./../../../src/pages/locations/san-diego-translation-services.js" /* webpackChunkName: "component---src-pages-locations-san-diego-translation-services-js" */),
  "component---src-pages-locations-san-francisco-translation-services-js": () => import("./../../../src/pages/locations/san-francisco-translation-services.js" /* webpackChunkName: "component---src-pages-locations-san-francisco-translation-services-js" */),
  "component---src-pages-locations-seattle-translation-services-js": () => import("./../../../src/pages/locations/seattle-translation-services.js" /* webpackChunkName: "component---src-pages-locations-seattle-translation-services-js" */),
  "component---src-pages-locations-sydney-translation-services-js": () => import("./../../../src/pages/locations/sydney-translation-services.js" /* webpackChunkName: "component---src-pages-locations-sydney-translation-services-js" */),
  "component---src-pages-locations-toronto-translation-services-js": () => import("./../../../src/pages/locations/toronto-translation-services.js" /* webpackChunkName: "component---src-pages-locations-toronto-translation-services-js" */),
  "component---src-pages-locations-washington-dc-translation-services-js": () => import("./../../../src/pages/locations/washington-dc-translation-services.js" /* webpackChunkName: "component---src-pages-locations-washington-dc-translation-services-js" */),
  "component---src-pages-medical-interpretation-js": () => import("./../../../src/pages/medical-interpretation.js" /* webpackChunkName: "component---src-pages-medical-interpretation-js" */),
  "component---src-pages-notarized-translation-php-js": () => import("./../../../src/pages/notarized-translation.php.js" /* webpackChunkName: "component---src-pages-notarized-translation-php-js" */),
  "component---src-pages-order-info-js": () => import("./../../../src/pages/order-info.js" /* webpackChunkName: "component---src-pages-order-info-js" */),
  "component---src-pages-our-customers-js": () => import("./../../../src/pages/our-customers.js" /* webpackChunkName: "component---src-pages-our-customers-js" */),
  "component---src-pages-patent-translation-php-js": () => import("./../../../src/pages/patent-translation.php.js" /* webpackChunkName: "component---src-pages-patent-translation-php-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-privacy-php-js": () => import("./../../../src/pages/privacy.php.js" /* webpackChunkName: "component---src-pages-privacy-php-js" */),
  "component---src-pages-project-timer-js": () => import("./../../../src/pages/project-timer.js" /* webpackChunkName: "component---src-pages-project-timer-js" */),
  "component---src-pages-quality-policy-js": () => import("./../../../src/pages/quality-policy.js" /* webpackChunkName: "component---src-pages-quality-policy-js" */),
  "component---src-pages-quote-checkout-js": () => import("./../../../src/pages/quote/checkout.js" /* webpackChunkName: "component---src-pages-quote-checkout-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-quote-order-summary-js": () => import("./../../../src/pages/quote/order-summary.js" /* webpackChunkName: "component---src-pages-quote-order-summary-js" */),
  "component---src-pages-quote-success-js": () => import("./../../../src/pages/quote/success.js" /* webpackChunkName: "component---src-pages-quote-success-js" */),
  "component---src-pages-real-time-translation-js": () => import("./../../../src/pages/real-time-translation.js" /* webpackChunkName: "component---src-pages-real-time-translation-js" */),
  "component---src-pages-reviews-php-js": () => import("./../../../src/pages/reviews.php.js" /* webpackChunkName: "component---src-pages-reviews-php-js" */),
  "component---src-pages-supported-languages-js": () => import("./../../../src/pages/supported-languages.js" /* webpackChunkName: "component---src-pages-supported-languages-js" */),
  "component---src-pages-tomedes-insider-php-js": () => import("./../../../src/pages/tomedes-insider.php.js" /* webpackChunkName: "component---src-pages-tomedes-insider-php-js" */),
  "component---src-pages-tools-data-anonymization-js": () => import("./../../../src/pages/tools/data-anonymization.js" /* webpackChunkName: "component---src-pages-tools-data-anonymization-js" */),
  "component---src-pages-tools-glossary-generator-js": () => import("./../../../src/pages/tools/glossary-generator.js" /* webpackChunkName: "component---src-pages-tools-glossary-generator-js" */),
  "component---src-pages-tools-guideline-adherence-checker-js": () => import("./../../../src/pages/tools/guideline-adherence-checker.js" /* webpackChunkName: "component---src-pages-tools-guideline-adherence-checker-js" */),
  "component---src-pages-tools-index-js": () => import("./../../../src/pages/tools/index.js" /* webpackChunkName: "component---src-pages-tools-index-js" */),
  "component---src-pages-tools-language-detector-js": () => import("./../../../src/pages/tools/language-detector.js" /* webpackChunkName: "component---src-pages-tools-language-detector-js" */),
  "component---src-pages-tools-text-summarizer-js": () => import("./../../../src/pages/tools/text-summarizer.js" /* webpackChunkName: "component---src-pages-tools-text-summarizer-js" */),
  "component---src-pages-tools-topic-detection-js": () => import("./../../../src/pages/tools/topic-detection.js" /* webpackChunkName: "component---src-pages-tools-topic-detection-js" */),
  "component---src-pages-tools-translation-consistency-checker-js": () => import("./../../../src/pages/tools/translation-consistency-checker.js" /* webpackChunkName: "component---src-pages-tools-translation-consistency-checker-js" */),
  "component---src-pages-tools-translation-difficulty-checker-index-js": () => import("./../../../src/pages/tools/translation-difficulty-checker/index.js" /* webpackChunkName: "component---src-pages-tools-translation-difficulty-checker-index-js" */),
  "component---src-pages-tools-translation-quality-assurance-index-js": () => import("./../../../src/pages/tools/translation-quality-assurance/index.js" /* webpackChunkName: "component---src-pages-tools-translation-quality-assurance-index-js" */),
  "component---src-pages-tools-word-count-ratio-index-js": () => import("./../../../src/pages/tools/word-count-ratio/index.js" /* webpackChunkName: "component---src-pages-tools-word-count-ratio-index-js" */),
  "component---src-pages-transcript-translation-services-js": () => import("./../../../src/pages/transcript-translation-services.js" /* webpackChunkName: "component---src-pages-transcript-translation-services-js" */),
  "component---src-pages-transcription-services-js": () => import("./../../../src/pages/transcription-services.js" /* webpackChunkName: "component---src-pages-transcription-services-js" */),
  "component---src-pages-translation-conference-add-js": () => import("./../../../src/pages/translation-conference/add.js" /* webpackChunkName: "component---src-pages-translation-conference-add-js" */),
  "component---src-pages-translation-cost-js": () => import("./../../../src/pages/translation-cost.js" /* webpackChunkName: "component---src-pages-translation-cost-js" */),
  "component---src-pages-translation-industry-php-js": () => import("./../../../src/pages/translation-industry.php.js" /* webpackChunkName: "component---src-pages-translation-industry-php-js" */),
  "component---src-pages-translation-services-js": () => import("./../../../src/pages/translation-services.js" /* webpackChunkName: "component---src-pages-translation-services-js" */),
  "component---src-pages-translation-technology-js": () => import("./../../../src/pages/translation-technology.js" /* webpackChunkName: "component---src-pages-translation-technology-js" */),
  "component---src-pages-translation-tips-js": () => import("./../../../src/pages/translation-tips.js" /* webpackChunkName: "component---src-pages-translation-tips-js" */),
  "component---src-pages-translation-tips-sharetip-js": () => import("./../../../src/pages/translation-tips/sharetip.js" /* webpackChunkName: "component---src-pages-translation-tips-sharetip-js" */),
  "component---src-pages-uscis-translation-services-js": () => import("./../../../src/pages/uscis-translation-services.js" /* webpackChunkName: "component---src-pages-uscis-translation-services-js" */),
  "component---src-pages-useful-links-js": () => import("./../../../src/pages/useful-links.js" /* webpackChunkName: "component---src-pages-useful-links-js" */),
  "component---src-pages-user-agreement-js": () => import("./../../../src/pages/user-agreement.js" /* webpackChunkName: "component---src-pages-user-agreement-js" */),
  "component---src-pages-user-agreement-php-js": () => import("./../../../src/pages/user-agreement.php.js" /* webpackChunkName: "component---src-pages-user-agreement-php-js" */),
  "component---src-pages-user-auth-login-js": () => import("./../../../src/pages/user/auth/login.js" /* webpackChunkName: "component---src-pages-user-auth-login-js" */),
  "component---src-pages-user-change-password-js": () => import("./../../../src/pages/user/change-password.js" /* webpackChunkName: "component---src-pages-user-change-password-js" */),
  "component---src-pages-user-dashboard-change-password-js": () => import("./../../../src/pages/user/dashboard/change-password.js" /* webpackChunkName: "component---src-pages-user-dashboard-change-password-js" */),
  "component---src-pages-user-dashboard-js": () => import("./../../../src/pages/user/dashboard.js" /* webpackChunkName: "component---src-pages-user-dashboard-js" */),
  "component---src-pages-user-dashboard-old-projects-js": () => import("./../../../src/pages/user/dashboard/old-projects.js" /* webpackChunkName: "component---src-pages-user-dashboard-old-projects-js" */),
  "component---src-pages-user-forgot-password-js": () => import("./../../../src/pages/user/forgot-password.js" /* webpackChunkName: "component---src-pages-user-forgot-password-js" */),
  "component---src-pages-user-login-js": () => import("./../../../src/pages/user/login.js" /* webpackChunkName: "component---src-pages-user-login-js" */),
  "component---src-pages-user-provider-createaccount-js": () => import("./../../../src/pages/user/provider/createaccount.js" /* webpackChunkName: "component---src-pages-user-provider-createaccount-js" */),
  "component---src-pages-user-register-js": () => import("./../../../src/pages/user/register.js" /* webpackChunkName: "component---src-pages-user-register-js" */),
  "component---src-pages-video-translation-js": () => import("./../../../src/pages/video-translation.js" /* webpackChunkName: "component---src-pages-video-translation-js" */),
  "component---src-pages-website-translation-multilingual-seo-services-js": () => import("./../../../src/pages/website-translation/multilingual-seo-services.js" /* webpackChunkName: "component---src-pages-website-translation-multilingual-seo-services-js" */),
  "component---src-pages-website-translation-wordpress-wpml-api-token-js": () => import("./../../../src/pages/website-translation/wordpress/wpml/api-token.js" /* webpackChunkName: "component---src-pages-website-translation-wordpress-wpml-api-token-js" */),
  "component---src-pages-website-translation-wordpress-wpml-index-js": () => import("./../../../src/pages/website-translation/wordpress/wpml/index.js" /* webpackChunkName: "component---src-pages-website-translation-wordpress-wpml-index-js" */),
  "component---src-pages-word-count-ratio-tool-helpeverybody-js": () => import("./../../../src/pages/word-count-ratio-tool/helpeverybody.js" /* webpackChunkName: "component---src-pages-word-count-ratio-tool-helpeverybody-js" */),
  "component---src-pages-word-count-ratio-tool-helpmyself-js": () => import("./../../../src/pages/word-count-ratio-tool/helpmyself.js" /* webpackChunkName: "component---src-pages-word-count-ratio-tool-helpmyself-js" */),
  "component---src-pages-word-count-ratio-tool-index-js": () => import("./../../../src/pages/word-count-ratio-tool/index.js" /* webpackChunkName: "component---src-pages-word-count-ratio-tool-index-js" */),
  "component---src-pages-wpml-wordpress-website-translation-js": () => import("./../../../src/pages/wpml-wordpress-website-translation.js" /* webpackChunkName: "component---src-pages-wpml-wordpress-website-translation-js" */),
  "component---src-pages-youtube-video-translation-js": () => import("./../../../src/pages/youtube-video-translation.js" /* webpackChunkName: "component---src-pages-youtube-video-translation-js" */)
}

