import React, { useRef, useState } from "react";
import DataContext from "./DataContext";

const LocalObject = typeof window !== "undefined" && window;
// global state management
const getLocalItems = () => {
  let accountInformation =
    LocalObject.localStorage !== undefined
      ? LocalObject.localStorage.getItem("acc_info")
      : "";
  if (accountInformation !== undefined) {
    return LocalObject.localStorage !== undefined
      ? JSON.parse(LocalObject.localStorage.getItem("acc_info"))
      : "";
  } else {
    return "";
  }
};
const handleTokenValue = () => {
  var tokenValue =
    LocalObject.localStorage !== undefined
      ? LocalObject.localStorage.getItem("access_token")
      : "";
  if (tokenValue !== undefined) {
    return LocalObject.localStorage !== undefined
      ? LocalObject.localStorage.getItem("access_token")
      : "";
  } else {
    return "";
  }
};

export const DataProvider = ({ children }) => {
  // sidebar component
  const [stepsData, setStepsData] = useState({});
  const [transtalteFromInput, setTranslateFromInput] = useState(
    "Select source language"
  );
  const [transtalteToInput, setTranslateToInput] = useState(
    "Select target language"
  );
  const [fileValue, setfileValue] = useState([]);
  const [numberOfWords, setNumberOfWords] = useState(0);
  const [storeValue, setStoreValue] = useState([]);
  const [transtalteServiceInput, setTranstalteServiceInput] = useState({
    category_name: "Document / Text translation",
    category_id: 1,
  });
  const [transtalteSubjectInput, setTranstalteSubjectInput] = useState({
    subcategory_name: "General Business / Commerce",
    subcategory_id: 1,
  });
  const [translateMarketingSourceInput, setTranslateMarketingSourceInput] =
    useState(1);

  const [quoteId, setQuoteId] = useState(0);

  const [token, setToken] = useState(handleTokenValue());
  const [allData, setAllData] = useState(null);
  const [accountInfo, setAccountInfo] = useState(getLocalItems());
  const [thirdStepData, setThirdStepData] = useState({});
  const [userEmail, setUserMail] = useState(null);
  const [userName, setUserName] = useState(null);
  const [toggleOrder, settoggleOrder] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState({});
  const [firstStepData, setFirstStepData] = useState({});
  const [nativeLangData, setNativeLangData] = useState([]);
  const [expertiseData, setExpertiseData] = useState([]);
  const [servicesData, setServicesData] = useState([]);
  const [originalLangData, setOriginalLangData] = useState([]);
  const [targetLangData, setTargetLangData] = useState([]);
  const [cvUpload, setCvUpload] = useState({});
  const [usernameFinalStepData, setUsernameFinalStepData] = useState();
  const [addressFinalStepData, setAddressFinalStepData] = useState();
  const [cityFinalStepData, setCityFinalStepData] = useState();
  const [staFinalStepData, setStaFinalStepData] = useState();
  const [countryFinalStepData, setCountryFinalStepData] = useState();
  const [zipFinalStepData, setZipFinalStepData] = useState();
  const [toggleStates, setToggleStates] = useState({
    analyticsCookies: true,
    functionalCookies: true,
    advertisingCookies: true,
  });
  const [showCookie, setShowCookie] = useState(false);
  const [cookiePreference, setCookiePreference] = useState(false);
  const scrollupform = useRef(null);

  return (
    <DataContext.Provider
      value={{
        cookiePreference,
        setCookiePreference,
        showCookie,
        setShowCookie,
        toggleStates,
        setToggleStates,
        stepsData,
        setStepsData,
        transtalteFromInput,
        setTranslateFromInput,
        transtalteToInput,
        setTranslateToInput,
        fileValue,
        setfileValue,
        paymentInfo,
        setPaymentInfo,
        numberOfWords,
        setNumberOfWords,
        storeValue,
        token,
        accountInfo,
        setAccountInfo,
        setToken,
        setStoreValue,
        toggleOrder,
        settoggleOrder,
        transtalteServiceInput,
        setTranstalteServiceInput,
        transtalteSubjectInput,
        setTranstalteSubjectInput,
        quoteId,
        setQuoteId,
        thirdStepData,
        setThirdStepData,
        LocalObject,
        allData,
        setAllData,
        setUserName,
        userName,
        userEmail,
        setUserMail,
        translateMarketingSourceInput,
        setTranslateMarketingSourceInput,
        firstStepData,
        setFirstStepData,
        nativeLangData,
        setNativeLangData,
        expertiseData,
        setExpertiseData,
        originalLangData,
        setOriginalLangData,
        targetLangData,
        setTargetLangData,
        cvUpload,
        setCvUpload,
        thirdStepData,
        setThirdStepData,
        servicesData,
        setServicesData,

        usernameFinalStepData,
        setUsernameFinalStepData,

        addressFinalStepData,
        setAddressFinalStepData,

        cityFinalStepData,
        setCityFinalStepData,

        staFinalStepData,
        setStaFinalStepData,

        countryFinalStepData,
        setCountryFinalStepData,

        zipFinalStepData,
        setZipFinalStepData,
        scrollupform,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
